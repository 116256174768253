import { useEffect, useState } from 'react';

import { COMMON_LIBRARY_CONSTANTS } from '@netfront/common-library';
import { useDomain, useIdentitySiteUrls } from '@netfront/gelada-identity-library';
import { Button } from '@netfront/ui-library';
import { NextPage } from 'next';
import { useRouter } from 'next/router';

import { BUTTON_CLASSES, ClientsBanner, ContactForm, LinkButton, PageLayout } from '../components';
import { useToast, useSendHowlerContactForm } from '../hooks';

const Home: NextPage = () => {
  const { isDomainReady } = useDomain();
  const { getRegistrationUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { push } = useRouter();
  const { handleToastError } = useToast();

  const [registrationUrl, setRegistrationUrl] = useState<string>();

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setRegistrationUrl(getRegistrationUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  const { handleSendHowlerContactForm, isLoading = false } = useSendHowlerContactForm({
    onCompleted: ({ isCompleted }) => {
      if (isCompleted) {
        push(`/thank-you`).catch((error) => {
          handleToastError({
            error,
          });
        });

        return;
      }

      const {
        MESSAGES: {
          ERROR: { UNEXPECTED },
        },
      } = COMMON_LIBRARY_CONSTANTS;

      handleToastError({
        error: new Error(UNEXPECTED),
        shouldUseFriendlyErrorMessage: true,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  return (
    <PageLayout
      meta={{
        description: 'QuickCodes provides a simple QR code generator service where you can make, customise andd track QR codes',
        metaTitle: 'QR code generator. Create, customise and track QR codes',
      }}
      title="QR code Creator"
    >
      <section className="flex flex-col min-h-screen sm:min-h-screen md:min-h-screen lg:min-h-screen bg-gradient-wave bg-gradient-wave--whole">
        <div className="c-container mt-36">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full md:w-1/2 p-4 flex flex-col justify-center mb-8 md:mb-0">
              <h3 className="color-white">Welcome</h3>
              <h1 className="mb-8 text-uppercase color-white">Free QR code generator</h1>
              <p className="color-white border-l-5 text-xl weight-200 border-white pl-4">
                QuickCodes provides a free QR code generator service. Generate dynamic QR codes, track scans and customise the QR style and
                layout. Try out the QuickCodes QR code maker today!
              </p>
              <div>
                <LinkButton additionalClassNames={`${BUTTON_CLASSES.red} mb-4 mr-4`} text="Learn more" url="#learn-more" />
                {registrationUrl && (
                  <Button
                    linkButton={{
                      id: 'create-free-account',
                      url: registrationUrl,
                    }}
                    text="Create free account"
                    variant="cta"
                  />
                )}
              </div>
            </div>

            <div className="mx-auto p-4 flex flex-col justify-center">
              <img
                alt="Free QR code generator"
                className="lg:m-0 lg:max-w-4/5 fade-in"
                height={565}
                loading="lazy"
                src="/images/banner-graphic_2x.webp"
                width={482}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white" id="learn-more">
        <div className="c-container py-8 md:py-32">
          <div className="flex flex-wrap -mx-4">
            <div className="m-auto mb-6 md:mb-0 md:w-1/3 p-4 text-center flex items-center">
              <img
                alt="Configure screen"
                className="max-w-3/5 md:max-w-full"
                height={382}
                loading="lazy"
                src="/images/speech-laptop-icon_2x.webp"
                width={581}
              />
            </div>
            <div className="w-full md:w-2/3 p-4">
              <p className="text-uppercase weight-500 mb-6">Create, customise and track your QR codes</p>
              <h2 className="font-header">Create, customise and track your QR codes</h2>
              <p>
                Generate a QR code with QuickCodes quick and easy QR Code maker. Customise your QR code look by controlling the background
                and dot colour. Use dynamic QR codes to track how many scans your code receives and download usage reports.
              </p>
              <p className="mb-12">
                Choose from different QR code types including scanning QR codes to website links, apps, text, email, PDF attachments and
                more. Set up permalinks with your QR codes to allow you to change the link or QR code information after the QR code has been
                generated giving you peace of mind if you&#39;re printing your QR code.
              </p>
              {registrationUrl && (
                <LinkButton additionalClassNames={BUTTON_CLASSES.red} text="Sign up for a free account" url={registrationUrl} />
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gradient-wave bg-gradient-wave--minimal py-16 md:py-32 relative">
        <div className="c-container color-white">
          <p className="text-uppercase">QR code features</p>
          <h2 className="font-header">Free QR code tier available</h2>
          <div className="flex flex-col md:flex-row mb-6 md:mb-0">
            <ul className="pl-6 mb-0 mr-8">
              <li className="ml-0">Unlimited codes</li>
              <li className="ml-0">Instant QR Code generation</li>
              <li className="ml-0">QR code tracking</li>
            </ul>
            <ul className="pl-6 mb-0">
              <li className="ml-0">QR code theming</li>
              <li className="ml-0">Download SVG, PNG and JPG</li>
              <li className="ml-0">QR code to PDF</li>
            </ul>
          </div>

          <img alt="Customise your QuickCodes tool" className="c-ui-graphic" loading="lazy" src="/images/ui-graphic.svg" />
        </div>
      </section>

      <section className="bg-white py-8 md:py-32">
        <div className="c-container">
          <div className="flex flex-wrap -mx-12">
            <div className="w-full md:w-1/3 py-4 px-12 md:py-12">
              <h3 className="h2 font-header">QR code generator</h3>
              <p>Instant QR code generator. Setup and manage QR codes all in one place and monitor the QR code usage.</p>
            </div>
            <div className="w-full md:w-1/3 py-4 px-12 md:py-12">
              <h3 className="h2 font-header">Scan QR codes to an attachment</h3>
              <p>Use a custom trackable QR code to scan to a downloadable attachment. Scan a QR code and download a menu or brochure</p>
            </div>
            <div className="w-full md:w-1/3 py-4 px-12 md:py-12">
              <h3 className="h2 font-header">Transparent pricing and usage</h3>
              <p>
                Free tier available, competitive pricing for higher usage. Pay for what you use and see real-time analytics and usage
                reports.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gradient-wave bg-gradient-wave--minimal py-32">
        <div className="c-container text-center color-white">
          <h2 className="font-header">Get started now</h2>
          <p className="mb-8 h4">It&#39;s quick and easy to get set up, try QuickCodes today for free!</p>
          {registrationUrl && <LinkButton additionalClassNames={BUTTON_CLASSES.red} text="Sign up now for free" url={registrationUrl} />}
        </div>
      </section>

      <section>
        <div className="c-container py-20">
          <div className="text-center mb-16">
            <h3 className="h2 font-header">Do you have any questions?</h3>
            <p>Get in touch with the QuickCodes team using the contact form below.</p>
          </div>
          <div className="max-w-lg mx-auto">
            <ContactForm
              isSubmitting={isLoading}
              onSend={({ email, firstName, lastName, message, organisationName, phoneNumber }) =>
                void handleSendHowlerContactForm({
                  email,
                  firstName,
                  lastName,
                  message,
                  phoneNumber,
                  placeholders: [
                    { key: '[Firstname]', value: firstName },
                    { key: '[Lastname]', value: lastName },
                    { key: '[Email]', value: email },
                    { key: '[Phonenumber]', value: phoneNumber },
                    { key: '[Organisation]', value: organisationName },
                    { key: '[Message]', value: message },
                  ],
                  projectId: String(process.env.REACT_APP_PROJECT_ID),
                })
              }
            />
          </div>
        </div>
      </section>

      <ClientsBanner />
    </PageLayout>
  );
};

// eslint-disable-next-line import/no-default-export
export default Home;
